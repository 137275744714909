import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix, useStaticQuery } from 'gatsby'
import '../all.scss'
import FirebaseContext from '../firebase/FirebaseContext'

const Root = ({ children }) => {
  const [firebase, setFirebase] = useState(null)
  useEffect(() => {
    const _firebase = require('firebase/app')
    require('firebase/analytics')

    _firebase.initializeApp({
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
      measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
    })
    setFirebase(_firebase)
  }, [])

  const data = useStaticQuery(graphql`
    query RootQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/x-icon"
          href={`${withPrefix('/')}img/favicon.ico`}
        />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
      </Helmet>
      <FirebaseContext.Provider value={firebase}>
        {children}
      </FirebaseContext.Provider>
    </div>
  )
}

export default Root
