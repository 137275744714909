// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipe-note-help-ja-js": () => import("./../../../src/pages/recipe-note/help_ja.js" /* webpackChunkName: "component---src-pages-recipe-note-help-ja-js" */),
  "component---src-pages-recipe-note-help-js": () => import("./../../../src/pages/recipe-note/help.js" /* webpackChunkName: "component---src-pages-recipe-note-help-js" */),
  "component---src-pages-recipe-note-index-js": () => import("./../../../src/pages/recipe-note/index.js" /* webpackChunkName: "component---src-pages-recipe-note-index-js" */),
  "component---src-pages-recipe-note-privacy-policy-js": () => import("./../../../src/pages/recipe-note/privacy-policy.js" /* webpackChunkName: "component---src-pages-recipe-note-privacy-policy-js" */),
  "component---src-pages-recipe-note-terms-of-service-js": () => import("./../../../src/pages/recipe-note/terms-of-service.js" /* webpackChunkName: "component---src-pages-recipe-note-terms-of-service-js" */),
  "component---src-templates-archives-js": () => import("./../../../src/templates/archives.js" /* webpackChunkName: "component---src-templates-archives-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-latest-js": () => import("./../../../src/templates/latest.js" /* webpackChunkName: "component---src-templates-latest-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

