import { createContext, useContext, useEffect } from 'react'

const FirebaseContext = createContext(null)

export function useFirebase(fn) {
  const firebase = useContext(FirebaseContext)
  useEffect(() => {
    if (!firebase) {
      return
    }
    return fn(firebase)
  }, [firebase, fn])
}

export default FirebaseContext